import { Pipe, PipeTransform } from '@angular/core';

import { PlanType, SubscriptionCheckType } from '../+authenticated/+reports/shared/subscriptions/subscription.model';
import { SubscriptionService } from '../+authenticated/+reports/shared/subscriptions/subscription.service';
import {
  hasAtleastSubscriptionPlan,
  hasAtMostSubscriptionPlan,
  hasExactlySubscriptionPlan,
} from '../shared/subscription-plan/subscription-plan.directive';

@Pipe({
  name: 'planCheck',
  standalone: true,
})
export class PlanCheckPipe implements PipeTransform {
  public constructor(private subscriptionService: SubscriptionService) {}

  public transform(planType: PlanType, checkType: SubscriptionCheckType = SubscriptionCheckType.AT_LEAST): boolean {
    const currentSubscription = this.subscriptionService.getCurrentSubscription();
    if (!currentSubscription) {
      return false;
    }

    switch (checkType) {
      case SubscriptionCheckType.AT_MOST:
        return hasAtMostSubscriptionPlan(planType, currentSubscription);
      case SubscriptionCheckType.AT_LEAST:
        return hasAtleastSubscriptionPlan(planType, currentSubscription);
      case SubscriptionCheckType.EXACTLY:
        return hasExactlySubscriptionPlan(planType, currentSubscription);
      default:
        return false;
    }
  }
}
